/*
 * @Description:
 * @Author: gaohongmei
 * @Date: 2022-02-07 15:44:59
 * @LastEditors: gaohongmei
 * @LastEditTime: 2022-02-12 17:26:44
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/service",
    name: "service",
    component: () =>
      import(
        /* webpackChunkName: "AfterService" */ "../views/AfterService.vue"
      ),
  },
  {
    path: "/sass",
    name: "sass",
    component: () =>
      import(
        /* webpackChunkName: "AfterSaleSass" */ "../views/AfterSaleSass.vue"
      ),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior: () => ({ y: 0 }),
});

export default router;
