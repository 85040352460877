<!--
 * @Description: 
 * @Author: gaohongmei
 * @Date: 2022-02-07 16:15:26
 * @LastEditors: gaohongmei
 * @LastEditTime: 2022-02-24 17:32:56
-->
<template>
  <div class="top-layout">
    <div class="top-content">
      <div class="left-logo">
        <img
          style="height: 30px"
          src="/images/logo.png"
          alt=""
        />
      </div>
      <div class="menu-wrap">
        <a-menu
          v-model="current"
          mode="horizontal"
        >
          <a-menu-item key="home">
            <router-link to="/">首页</router-link>
          </a-menu-item>
          <a-menu-item key="service">
            <router-link to="/service">售后服务平台</router-link>
          </a-menu-item>
          <a-menu-item key="sass">
            <router-link to="/sass">售后SASS</router-link>
          </a-menu-item>
          <a-menu-item key="about">
            <router-link to="/about">关于我们</router-link>
          </a-menu-item>
          <a-menu-item key="login">
            <a href="http://w.yixinjishufuwu.com/yixin/#/clogin" target="_blank">登录</a>
          </a-menu-item>
        </a-menu>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "./Footer.vue";
export default {
  components: {
    Footer,
  },
  data () {
    return {
      current: ["home"],
    };
  },
};
</script>
<style lang="less" scoped>
.top-layout {
  .top-content {
    max-width: 1200px;
    margin: 0 auto;
    height: 60px;
    display: flex;
    justify-content: space-between;
  }
  .left-logo {
    font-size: 30px;
    line-height: 60px;
    color: #04b5aa;
    font-style: italic;
  }
  .content {
    // max-width: 1200px;
    // margin: 0 auto;
  }
}
.menu-wrap {
  /deep/ .ant-menu-horizontal {
    line-height: 60px;
  }
  /deep/ .ant-menu {
    font-size: 18px !important;
    border-bottom: none;
  }
  /deep/ .ant-menu-item {
    border-bottom: none !important;
  }
}
</style>
