<template>
  <div class="home">
    <div class="cont1 top-carousel">
      <div class="container">
        <h1>
          数智服务平台
          <p>助力传统售后模式全面进入互联网时代</p>
        </h1>
        <img src="../assets/svg/home1.svg" />
        <!-- <a-carousel>
        <div class="main-cont carousel-cont">
          <img
            class="index-banner"
            src="/images/index-banner1.png"
            alt=""
          />
        </div>
        <div class="main-cont carousel-cont">
          <img
            class="index-banner"
            src="/images/index-banner1.png"
            alt=""
          />
        </div>
      </a-carousel> -->
      </div>
    </div>
    <div class="cont2">
      <div class="main-cont">
        <div class="ms letterSpacing6">以用户为中心的售后服务产品</div>
        <div class="msSubTitle">
          椅鑫服务专注建立线上售后服务体系，助力传统售后模式全面进入互联网时代
        </div>
        <div class="intro-wrap">
          <div class="intro-item">
            <div class="circle-wrap">
              <img src="/images/home_icon1.png" alt="" />
            </div>
            <div>用户体验</div>
          </div>
          <div class="intro-item">
            <div class="circle-wrap">
              <img src="/images/home_icon2.png" alt="" />
            </div>
            <div>智能下单</div>
          </div>
          <div class="intro-item">
            <div class="circle-wrap">
              <img src="/images/home_icon3.png" alt="" />
            </div>
            <div>智能运营</div>
          </div>
          <div class="intro-item">
            <div class="circle-wrap">
              <img src="/images/home_icon4.png" alt="" />
            </div>
            <div>数据管理</div>
          </div>
          <div class="intro-item">
            <div class="circle-wrap">
              <img src="/images/home_icon5.png" alt="" />
            </div>
            <div>师傅履约</div>
          </div>
        </div>
      </div>
    </div>
    <div class="cont3">
      <div class="main-cont">
        <div class="ms letterSpacing6">用科技驱动售后服务品质</div>
        <div class="msSubTitle">
          坚持品质交付，专注售后管理，用互联网科技的力量，为企业提供最优质的售后服务综合解决方案。
        </div>
        <div class="quality">
          <div class="quality-left">
            <p style="font-size: 24px">售后综合服务平台</p>
            <p>通过互联网，大数据，AI驱动行业效率和服务质量</p>
            <div @click="() => $router.push('/service')" class="more-button">
              了解更多
            </div>
          </div>
          <img style="width: 470px" src="../assets/svg/home2.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="cont4">
      <div class="main-cont">
        <div class="sass">
          <img style="width: 470px" src="../assets/svg/home3.svg" alt="" />
          <div class="sass-right">
            <p style="font-size: 24px">售后saas解决方案</p>
            <p>订单处理更智能，满足多样下单场景，更高效的履约</p>
            <div @click="() => $router.push('/sass')" class="more-button">
              了解更多
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style lang="less" scoped>
.container {
  width: 1000px;
  margin: 0 auto;
}
.top-carousel {
  width: 100%;
  // background: linear-gradient(90deg, rgb(20, 155, 177), rgb(45, 49, 145));
  background: #4d7aff; //url("../assets/svg/home1.svg") center no-repeat;
  background-size: 500px;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 120px 0 0;
    img {
      width: 520px;
    }
    h1 {
      text-align: left;
      font-size: 40px;
      margin-top: -120px;
    }
    p {
      font-size: 18px;
      margin-top: 10px;
    }
    h1,
    p {
      color: #fff;
    }
  }
}
.main-cont {
  width: 980px !important;
  margin: 0 auto;
}
.index-banner {
  width: 1200px;
}
.carousel-cont {
  display: flex !important;
  height: 550px;
  align-items: flex-end;
}
.cont2 {
  background-color: rgb(248, 248, 248);
  .intro-wrap {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    padding-bottom: 80px;
  }
  .circle-wrap {
    width: 81px;
    height: 81px;
    background: rgba(77, 122, 255, 0.1);
    border-radius: 50%;
    text-align: center;
    line-height: 81px;
    color: #4d7aff;
    font-size: 32px;
    margin-bottom: 6px;
  }
}
.cont3 {
  .quality {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .quality-left {
    text-align: left;
  }
  padding-bottom: 60px;
}
.cont4 {
  background-color: rgb(248, 248, 248);
  .sass {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 110px 0;
  }
  .sass-right {
    text-align: right;
  }
  padding: 60px 0;
}
.more-button {
  background: #4d7aff;
  color: #fff;
  font-size: 20px;
  padding: 10px 50px;
  line-height: 28px;
  margin-top: 60px;
  display: inline-block;
}
.ms {
  font-size: 36px;
  text-align: center;
  padding: 120px 0 10px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.65);
}
.letterSpacing6 {
  letter-spacing: 6px;
}
.msSubTitle {
  color: #666;
  font-size: 24px;
  text-align: center;
  margin: 0 0 80px;
}
</style>
